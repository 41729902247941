.addBikeOption {
  padding: 8px 16px 16px;
  max-width: 390px;
  margin: 0 auto;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
}

.backButton {
  position: absolute;
  left: -8px;
  top: 8px;
  width: 40px;
  height: 40px;
  background: #fff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../assets/images/ChevronLeft.svg");
  cursor: pointer;
}

.pageTitle {
  padding-top: 8px;
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #272727;
}

.label {
  padding-top: 32px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #272727;
}

.input {
  margin-top: 8px;
}

.footerFiller {
  height: 64px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  padding: 8px 16px;
  z-index: 1;
}

.submitButton {
  display: flex;
  justify-content: space-between;
  background: #02f596;
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  padding: 12px 16px;
  cursor: pointer;
}

.submitButtonText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #272727;
}

.submitButtonIcon {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../assets/images/Check.svg");
}

.description {
  padding-top: 16px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #737373;
}

.descriptionLink {
  color: #2e6bed;
  cursor: pointer;
}

@media (min-width: 1280px) {
  .footer {
    padding-left: 10%;
    padding-right: 60%;
  }
}
