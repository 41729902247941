.wrapper {
  width: 24.375rem;
  margin: 0 auto;
}
.productCard {
  width: 20.437rem;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 12px 24px -4px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem 1rem 1rem;
  margin-left: 1rem;
  margin-top: 3rem;
}

.productCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productCardLogo {
  display: block;
  background-image: url("../../assets/images/ProductCardLogo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 5rem;
  height: 1rem;
}

.productCardLabel {
  display: block;
  padding: 0.25rem 0.5rem;
  background: #02f596;
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.33;
  color: #2e3840;
}

.dashboaedBikeImageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboaedBikeImage {
  display: block;
  background-image: url("../../assets/images/BikeProductCardImage.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 16.437rem;
  height: 10rem;
  margin-top: 1.5rem;
}

.productCardFooter {
  margin-top: 1.33rem;
}

.productCardFooterTitle {
  font-weight: 600;
  font-size: 1.33rem;
  line-height: 1.33;
  text-transform: uppercase;
  color: #5e7387;
}

.productCardFooterDescr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productName {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #2e3840;
}

.productCardDetails {
  display: flex;
  align-items: center;
}
.productCardDetailsTitle {
  margin-right: 0.5rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  color: #2e3840;
}

.productCardDetailsIcon {
  display: block;
  background-image: url("../../assets/images/ViewListIcon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.5rem;
  height: 1.5rem;
}

.dashboardPageIconsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.iconsContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.iconsWrapper {
  display: block;
  position: relative;
  width: 3rem;
  height: 3rem;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}
.firstIcon {
  display: block;
  position: absolute;
  top: 28%;
  right: 25%;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("../../assets/images/DownloadIcon.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.secondIcon {
  display: block;
  position: absolute;
  top: 28%;
  right: 25%;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("../../assets/images/AddPhotoIcon.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.thirdIcon {
  display: block;
  position: absolute;
  top: 28%;
  right: 25%;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("../../assets/images/FileIcon.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.fourthIcon {
  display: block;
  position: absolute;
  top: 28%;
  right: 25%;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("../../assets/images/TrailingIcon.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.fifthIcon {
  display: block;
  position: absolute;
  top: 28%;
  right: 25%;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("../../assets/images/TrailingIcon.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.dashboardIconDescr {
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.33;
  color: #2e3840;
  text-align: center;
}

.dashboardDetailsCardWrapper {
  display: flex;
  flex-wrap: wrap;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.dashboardDetailsCard {
  width: 10.687rem;
  height: 4rem;
  padding: 0.75rem 0.75rem 0.75rem 1rem;
  background: #ffffff;
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
}

.dashboardDetailsCard:nth-child(odd) {
  margin-right: 1rem;
}
.dashboardDetailsCard:nth-last-child(2n) {
  margin-bottom: 1rem;
}

.dashboardDetailsCardFirst {
  display: flex;
  align-items: center;
}
.dashboardDetailsCardTitle {
  margin-bottom: 0.25rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25;
  color: #2e3840;
}

.dashboardDetailsCardSubtitleFirst {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.14;
  color: #3b75ed;
}
.dashboardDetailsCardSubtitle {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.14;
  color: #5e7387;
}

.progressCircleIcon {
  display: block;
  width: 2rem;
  height: 2rem;
  margin-left: 0.875rem;
  background-image: url("../../assets/images/ProgressCircle.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.mainMapWrapper {
  padding-right: 1rem;
  padding-left: 1rem;
}

.mapWrapper {
  width: 22.375rem;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
}

.mapTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.mapTitle {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  color: #2e3840;
}
.arrowIcon {
  display: block;
  width: 1.125rem;
  height: 1.125rem;
  background-image: url("../../assets/images/ArrowIcon.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.mapImage {
  display: block;
  width: 22.375rem;
  height: 10rem;
  background-image: url("../../assets/images/MapImage.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.mainContactWrapper {
  margin-top: 1.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
}
.contactWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.contactItem {
  display: block;
  border: 0.125rem solid #d1d9e0;
  border-radius: 0.5rem;
  padding: 1rem 1.625rem 1rem 1.625rem;
}

.instagramIconWrapper {
  display: block;
  border: 0.125rem solid #d1d9e0;
  border-radius: 0.5rem;
  padding: 0.75rem;
}
.instagramIcon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("../../assets/images/InstaIcon.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.mailwrapper {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  border: 0.125rem solid #d1d9e0;
  box-sizing: border-box;
  border-radius: 0.5rem;
  padding: 1rem;
}
.mailIcon {
  display: block;
  width: 1.125rem;
  height: 0.875rem;
  margin-right: 0.687rem;
  background-image: url("../../assets/images/MailIcon.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.dashboardPageFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.937rem;
  background: #ffffff;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.1),
    0px 0px 16px -2px rgba(0, 0, 0, 0.2);
  padding: 0.75rem 3.125rem 0.75rem 3.125rem;
}
.footerIconsWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bikeIcon {
  display: block;
  width: 2rem;
  height: 1.125rem;
  background-image: url("../../assets/images/BikeIcon.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.trailingIconDone {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("../../assets/images/TrailingIconDone.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.userIcon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("../../assets/images/UserIcon.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.footerIconText {
  margin-top: 0.125rem;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.33;
  color: #2e3840;
}

.footerIconTextFirst {
  margin-top: 0.125rem;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.33;
  color: #3b75ed;
}

.dashboardPageInfoWrapper {
  padding-left: 1.375rem;
  padding-right: 1.375rem;
  margin-top: 0.5rem;
  padding-bottom: 10vh;
}
.dashboardPageInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 21.625rem;
  background: rgba(255, 255, 255, 0.65);
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.19);
  backdrop-filter: blur(48px);
  border-radius: 0.75rem;
  padding: 0.875rem 0.75rem 0.875rem 0.75rem;
}
.letterIcon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("../../assets/images/AletterIcon.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.returnArrow {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("../../assets/images/ReturnArrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.text {
  font-family: "Inter";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1;
}
