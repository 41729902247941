.title {
  font-weight: 700;
  font-size: 3rem;
  line-height: 1;
  color: #2e3840;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.pageWrapper {
  padding: 0 16px;
}
.wrapper {
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 1rem 1rem 10px;
  margin-bottom: 1.5rem;
}
.descriptionTitle {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #2e3840;
  margin-bottom: 8px;
}
.listItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.boldListItem .text {
  font-weight: 600;
}
.listItem:last-child {
  margin-bottom: 0.5rem;
}
.text {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.14;
  color: #2e3840;
}
.container {
  display: flex;
  justify-content: flex-end;
}
.textDescription {
  font-size: 0.875rem;
  line-height: 1.43;
  color: #5e7387;
}
.selectBtn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  border: none;
  padding: 0.75rem 1rem;
}
.spanText {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #2e3840;
}
.downloadLink {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 1.5rem;
  padding: 0.75rem 1rem;
  width: 90%;
  margin-top: 0.5rem;
  margin-left: 2.5rem;
  align-items: center;
  box-shadow: 0 0.5rem 1rem -0.125rem rgba(0, 0, 0, 0.1);
}
.downloadLink:hover {
  background-color: #e9f0fc;
}
.span {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.71;
  color: #3b75ed;
}
.iconDownload {
  display: block;
  background-image: url("../../../assets/images/DownloadIconBlue.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.5rem;
  height: 1.5rem;
}
.wrapperCard {
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}
.wrapperSpan {
  display: flex;
  justify-content: space-between;
}
.iconChevronUp {
  display: block;
  background-image: url("../../../assets/images/ChevronUp.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.5rem;
  height: 1.5rem;
}
.iconChevronDown {
  display: block;
  background-image: url("../../../assets/images/ChevronUp.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.5rem;
  height: 1.5rem;
  transform: rotate(180deg);
}
.wrapperDiv {
  display: flex;
  margin-top: 1rem;
}
.wrapperList:not(:last-child) {
  margin-right: 8rem;
}
.itemDescription {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.14;
  color: #5e7387;
}
.itemDescriptionText {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  color: #2e3840;
  margin-bottom: 0.5rem;
}
.itemP {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #5e7387;
}
.ibanWrapper {
  padding-top: 0.5rem;
}
.wrapperBottom {
  display: flex;
}
.secondCheckbox {
  margin: 1.125rem 0 1.25rem 0;
}
.errorMessage {
  color: red;
  text-align: center;
  margin-bottom: 0.625rem;
  font-size: 0.75rem;
}
.toast {
  border: 0.125rem solid red;
  color: #2e3840;
  font-size: 0.875rem;
}
.toastButton {
  width: 2rem;
  height: 2rem;
  background-image: url(../../../assets/images/CrossRed.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border: none;
  background-color: #ffffff;
}
.priceSection {
  padding-top: 16px;
  margin-top: 16px;
  border-top: 2px solid #f3f3f3;
}
.offerText {
  display: flex;
  width: fit-content;
  align-items: center;
  font-family: "Inter";
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #0d0d0d;
  background: #ffde1a;
  border-radius: 20px;
  padding: 2px 8px;
}
.offerText:not(:last-child) {
  margin-right: 8px;
}
.tooltip {
  display: inline-block;
  background-image: url(../../../assets/images/Tooltip.svg);
  margin-left: 3px;
  width: 14px;
  height: 14px;
  background-size: contain;
  background-repeat: no-repeat;
}
.list {
  margin-top: 16px;
}
