.label {
  display: flex;
  flex-direction: column;
  position: relative;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  color: #2e3840;
  margin-top: 1rem;
}

.input {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #b5bfcc;
  padding: 0.75rem;
  height: 3rem;
  background: #ffffff;
  box-shadow: 0 0.375rem 0.75rem -0.125rem rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  border: none;
  margin-top: 0.5rem;
}

.input:focus {
  outline: #5c8cf0;
  border: 2px solid #5c8cf0;
}

.input:not(placeholder-shown) {
  color: #2e3840;
}

.errorMessage {
  color: red;
  text-align: center;
  margin-top: 0.625rem;
  font-size: 0.75rem;
}

.contentWrapper {
  padding-top: 27px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 136px;
}


.btn {
  display: flex;
  background-color: #02f596;
  border: none;
  padding: 8px 16px 8px 16px;
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.2);
  border-radius:24px;
  text-align: left;
  justify-content: space-between;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: #2e3840;
  width: 100%;
  margin-top: 1rem;
}

.header {
  display: flex;
  justify-content: center;
}

.pageTitle {
  font-family: 'Inter';
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  color: #2e3840;
}

.description {
  margin-top: 1rem;
  font-size: 14px;
}
