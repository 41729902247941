.sectionFooter {
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #f0f2f5;
  padding-top: 12px;
  cursor: pointer;
}

.expandText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3b75ed;
}

.expandSymbol {
  background-image: url("../../../assets/images/BlueChevron.svg");
  width: 24px;
  height: 24px;
}

.reverseExpandSymbol {
  transform: rotate(180deg);
}
