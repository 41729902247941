.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  background: #fff;
  height: 48px;
  width: 100%;
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  cursor: pointer;
}

.button:hover{
  background-color: #4DFCA6; 
  color: black;
}

.button:active{
  background-color: #45E38A; 
  color: black;
}

.buttonText {
  font-family: 'Inter';
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #2e3840;
}

.buttonIcon {
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
}
