.activateBike {
  position: relative;
  padding: 0 16px;
  max-width: 390px;
  margin: 0 auto;
}

.closeButton {
  position: absolute;
  left: 8px;
  top: 8px;
  width: 40px;
  height: 40px;
  background: #fff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/images/CloseBtn.svg");
  cursor: pointer;
}

.pageTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 54px;
  text-align: center;
  color: #0d0d0d;
}

.progressBar {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 8px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 8px;
}

.progressAmount {
  position: absolute;
  top: 0;
  left: 0;
  height: 8px;
  background: #02f596;
  border-radius: 4px;
}

.progressAmountSmall {
  width: 72px;
}

.progressAmountMedium {
  width: 120px;
}

.progressAmountBig {
  width: 133px;
}

.progressAmountMax {
  width: 200px;
}

.description {
  padding-top: 16px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
}

.listTitle {
  padding-top: 36px;
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #0d0d0d;
}

.option {
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 12px 0;
  text-align: center;
  margin-top: 8px;
  cursor: pointer;
}

.chosenOption {
  border: 2px solid #3b75ed;
  padding: 10px 0;
}

.footerFiller {
  height: 64px;
}

.submitButton {
  display: flex;
  justify-content: space-between;
  background: #02f596;
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  padding: 12px 16px;
  cursor: pointer;
}

.submitButtonText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #272727;
}

.submitButtonIcon {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/images/ArrowRight.svg");
}

.submitButtonIconComplete {
  background-image: url("../../assets/images/Check.svg");
}

.completeImage {
  width: 152px;
  height: 100px;
  margin: 0 auto;
  background-image: url("../../assets/images/SuccessBike.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.successMessage {
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #0d0d0d;
  padding-top: 16px;
}

.successDescription {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #0d0d0d;
  max-width: 323px;
  margin: 0 auto;
  padding-top: 61px;
}

.submitButtonFiller {
  height: 114px;
}
