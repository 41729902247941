.shareBikeButton {
  position: fixed;
  bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 32px);
  max-width: 358px;
  height: 48px;
  line-height: 48px;
  background: #02f596;
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 0 6px 0 16px;
  cursor:pointer;
}

.shareInFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  line-height: 48px;
  background: #02f596;
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 0 6px 0 16px;
  cursor:pointer;
}

.shareInFooter:hover{
  background-color: #4DFCA6; 
  color: black;
}

.shareInFooter:active{
  background-color: #45E38A; 
  color: black;
}

.shareBikeButtonText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  color: #2e3840;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.shareBikeButtonIcon {
  width: 40px;
  height: 40px;
  background-image: url("../../../assets/images/Share.svg");
  background-repeat: no-repeat;
  background-position: center 6px;
}

.toRightIcon {
  width: 40px;
  height: 40px;
  background-image: url("../../../assets/images/ArrowRight.svg");
  background-repeat: no-repeat;
  background-position: center;
}
