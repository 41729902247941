.section {
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.title {
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #2e3840;
}

.chevron {
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/ChevronRight.svg");
  transform: rotate(90deg);
}

.chevronReversed {
  transform: rotate(-90deg);
}

.content {
  transition: height 0.2s ease-in-out;
  overflow: hidden;
}
