.buttonsFiller {
  height: 88px;
  width: 100%;
}

.buttonsContainer {
  position: fixed;
  bottom: 16px;
  display: flex;
  gap: 16px;
  width: calc(100% - 32px);
}

.button {
  width: 50%;
}

@media (min-width: 1280px) {
  .buttonsFiller {
    display: none;
  }

  .buttonsContainer {
    position: static;
    width: 100%;
  }
}
