.footer {
  position: relative;
  padding: 156px 40px 140px;
  margin-top: 120px;
  background-color: #0d0d0d;
}

.newsletter {
  position: absolute;
  display: flex;
  top: 0;
  left: 40px;
  transform: translateY(-50%);
  width: calc(100% - 80px);
  background-color: #fff;
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
}

.newsletterMain,
.newsletterBanner {
  width: 50%;
}

.newsletterMain {
  padding: 60px 24px 60px 64px;
}

.newsletterTitle {
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #0d0d0d;
  padding-bottom: 8px;
}

.newsletterSubTitle {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0d0d0d;
  padding-bottom: 16px;
}

.newsletterInputContainer {
  display: flex;
  align-items: center;
  padding: 4px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1),
    0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 48px;
}

.newsletterInput,
.newsletterInput::placeholder {
  height: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0d0d0d;
  box-shadow: none;
}

.newsletterInput:focus {
  padding: 8px 11px;
}

.submitNewsletterButton {
  font-family: "Inter";
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  background-color: #0d0d0d;
  color: #fff;
  border-radius: 100px;
  padding: 8px 16px;
  margin-left: 4px;
  cursor: pointer;
}

.newsletterBanner {
  position: relative;
  background-image: url(".././../assets/images/newsletterBanner.png");
  background-size: cover;
}

.newsletterBanner:before {
  content: "";
  position: absolute;
  border-right: 100px solid transparent;
  border-top: 300px solid #fff;
}

.footerContainer {
  display: flex;
  justify-content: space-between;
  gap: 9px;
}

.description {
  width: calc(33.3% - 6px);
}

.logo {
  width: 144px;
  height: 32px;
  background-image: url("../../assets/images/LogoWhite.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.descriptionTitle {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #f3f3f3;
  padding-top: 40px;
}

.descriptionText {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #a5a8ab;
  padding-top: 16px;
}

.languageWidget {
  margin-top: 58px;
}

.copyright {
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #f3f3f3;
  padding-top: 40px;
}

.links {
  display: flex;
  justify-content: space-between;
  width: calc(33.3% - 6px);
  min-width: 310px;
}

.linkColumn {
  width: 45%;
}

.link {
  display: block;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  height: 20px;
  color: #f3f3f3;
  margin-top: 24px;
}

.link:first-child {
  margin-top: 0;
}

.link:hover {
  color: rgb(156 163 175);
}

.social {
  width: calc(33.3% - 6px);
}

.socialTitle {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #f3f3f3;
}

.email {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #f3f3f3;
  padding-top: 24px;
  cursor: pointer;
}

.socialIcons {
  display: flex;
  gap: 16px;
  padding: 19px 0 24px;
  border-bottom: 2px solid #272727;
}

.socialIcon {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 0;
  cursor: pointer;
}

.facebookIcon {
  background-image: url("../../assets/images/GreyFacebook.svg");
}

.twitterIcon {
  background-image: url("../../assets/images/GreyTwitter.svg");
}

.linkedInIcon {
  background-image: url("../../assets/images/GreyLinkedIn.svg");
}

.instagramIcon {
  background-image: url("../../assets/images/GreyInstagram.svg");
}

.inputContainer {
  position: relative;
}

@media (max-width: 1280px) {
  .footer {
    padding: 100px 16px 140px;
    margin-top: 88px;
  }

  .newsletter {
    left: 16px;
    width: calc(100% - 32px);
  }

  .newsletterMain {
    width: 100%;
    padding: 16px;
  }

  .footerContainer {
    flex-direction: column;
    gap: 0;
  }

  .logo {
    display: none;
  }

  .descriptionTitle {
    padding-top: 0;
  }

  .languageWidget {
    margin-top: 24px;
  }

  .copyright {
    padding-top: 24px;
  }

  .description,
  .links,
  .social {
    width: 100%;
  }

  .links {
    padding: 24px 0;
    border-bottom: 2px solid #272727;
  }

  .social {
    padding-top: 24px;
  }
}
