.myBike {
  position: relative;
  padding: 8px 16px;
  max-width: 390px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: center;
}

.backButton {
  position: absolute;
  left: 8px;
  top: 8px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-image: url("../../assets/images/ChevronLeft.svg");
}

.pageTitle {
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  color: #2e3840;
}

.section {
  margin-top: 16px;
  padding-bottom: 12px;
}

.shareBikeFiller {
  height: 114px;
}

.shareBikeButton {
  position: fixed;
  bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 32px);
  height: 48px;
  line-height: 48px;
  background: #02f596;
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 0 6px 0 16px;
}

.shareBikeButtonText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  color: #2e3840;
  flex: none;
  order: 0;
  flex-grow: 1;
}

.shareBikeButtonIcon {
  width: 40px;
  height: 40px;
  background-image: url("../../assets/images/Share.svg");
  background-repeat: no-repeat;
  background-position: center 6px;
}

.subheader {
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #2e3840;
  padding-top: 24px;
}

.brandName {
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #5e7387;
}

.modelName {
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #2e3840;
}

.subHeader {
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #2e3840;
  padding-top: 24px;
}

@media (min-width: 1280px) {
  .myBike {
    max-width: unset;
    padding-bottom: 88px;
  }
}
