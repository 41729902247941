.bike {
  position: relative;
  padding: 12px 16px 16px;
  width: 326px;
  background: #ffffff;
  box-shadow: 0px 12px 24px -4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: transform 0.2s linear;
  margin-right: 16px;
  cursor: pointer;
}

.logo {
  width: 55px;
  height: 17px;
  background-image: url("../../../assets/images/VelioLogo.svg");
  background-repeat: no-repeat;
}

.bikePhoto {
  width: 263px;
  height: 160px;
  margin: 24px auto 0;
  object-fit: cover;
}

.firm {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #5e7387;
  padding-top: 12px;
}

.actionSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.model {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #2e3840;
  white-space: nowrap;
  max-width: 205px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chosenBike {
  border: 4px solid #3b75ed;
  padding: 8px 12px 12px;
}

.chosenBike .insuranceStatus {
  right: 4px;
  top: 4px;
}
