.chosenSwitch {
  background-color: #5e7387;
  color: #fff;
}

.subtitle {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #2e3840;
  padding: 8px;
  background: #d1d9e0;
  border-radius: 8px;
  margin-top: 24px;
  width: 100%;
}
