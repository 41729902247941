@keyframes rotation {
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/Loader.png");
  background-size: contain;
  background-repeat: no-repeat;
  animation: rotation 1s infinite linear;
  animation-timing-function: steps(10);
}
