.checkbook {
  padding: 8px 16px 96px;
}

.contentWrapper {
  padding-top: 27px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 136px;
  max-width: 390px;
  margin: 0 auto;
}
/* header */
.header {
  display: flex;
  justify-content: space-between;
}

.backButton {
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.backButton {
  background-image: url("../../assets/images/ChevronLeft.svg");
}

.pageTitle {
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  color: #2e3840;
}

/* Subject bike */
.card {
  background: #ffffff;
  box-shadow: 0 12px 24px -4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 16px;
  margin: 16px auto;
}

.bikeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconBike {
  display: block;
  background-image: url("../../assets/images/Bike.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 80px;
  height: 48px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-right: 16px;
}
.text {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.08px;
  text-transform: uppercase;
  color: #5e7387;
}

.modelText {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: #2e3840;
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.08px;
  color: #2e3840;
}

.nextInspection {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.08px;
  text-transform: uppercase;
  color: #5e7387;
  margin-top: 16px;
  margin-bottom: 16px;
}

.hr {
  background-color: #d1d9e0;
  margin-top: 16px;
  margin-bottom: 16px;
  opacity: 0.8;
  height: 2px;
  border: none;
}

/* bottom transparent action panel */

.actionWrapper {
  background-color: transparent;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 8px 16px 16px 16px;
  z-index: 99;
}

.btn {
  display: flex;
  align-items: center;
  background-color: #02f596;
  border: none;
  padding: 12px 16px 12px 16px;
  box-shadow: 0px 0px 2px rgba(77, 64, 0, 0.1),
    0px 8px 16px -2px rgba(77, 64, 0, 0.2);
  border-radius: 24px;
  justify-content: space-between;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  color: #2e3840;
  width: 100%;
  cursor: pointer;
}

/* Event */
.cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.completedLabel {
  background-color: #02f596;
  border-radius: 20px;
  padding: 4px 8px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #2e3840;
}

.plannedLabel {
  background-color: #ffde1a;
  border-radius: 20px;
  padding: 4px 8px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #2e3840;
}

.cardHeaderText {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #2e3840;
}

.dateEntry {
  font-size: 14px;
  line-height: 20px;
  color: #5e7387;
}

.smallText {
  font-size: 12px;
}

.primaryButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #3b75ed;
  border: none;
  padding: 12px 16px;
  border-radius: 8px;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  width: 100%;
  cursor: pointer;
}

.moreDocument {
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/MoreDocument.svg");
}

.LoadingText {
  font-size: 24px;
  color: #5e7387;
}

.LoadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 16px;
}
