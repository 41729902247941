.hiddenImage {
  display: none !important;
}

.thumb .hiddenImage {
  display:inline-block !important;
}

.carousel .thumb {
  border-radius: 8px;
  border: 0px;
  padding: 0px;
}

.carousel .thumb.selected {
  border-radius: 8px;
  border: 2px solid #3B75ED;
}

