.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  padding: 52px 0;
  z-index: 101;
}

.closeButton {
  position: absolute;
  top: 16px;
  left: 16px;
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/WhiteClose.svg");
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.imageContainer {
  display: flex;
  flex-grow: 1;
  background-color: #f3f3f3;
  justify-content: center;
  align-items: center;
}

.image {
  max-width: 100vw;
  max-height: calc(100vh - 104px);
}
