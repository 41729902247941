.addBike {
  display: flex;
  flex-direction: column;
}

.addSection {
  display: flex;
  align-items: center;
  height: 280px;
  width: 100%;
  max-width: 326px;
  align-self: flex-end;
  background: #ffffff;
  box-shadow: 0px 12px 24px -4px rgb(0 0 0 / 10%);
  border-radius: 8px;
  transition: transform 0.2s linear;
  background-image: url("../../../assets/images/BikeCardAdd.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.transitionedAddSection {
  transform: translateX(-48px);
}

.addIcon {
  max-width: 44px;
  max-height: 44px;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/images/PlusIcon.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 125px;
  transition: margin 0.2s ease-in-out, max-height 0.2s ease-in-out,
    max-width 0.2s ease-in-out;
}

.transitionedAddIcon {
  margin-left: 9px;
  max-height: 14px;
  max-width: 14px;
}

.infoBlock {
  transition: opacity 0.2s linear;
}

.opacity0 {
  opacity: 0;
}

.header {
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #373737;
  padding-top: 24px;
}

.description {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #737373;
  padding-top: 16px;
}

.addButton {
  position: relative;
  background: #02f596;
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  padding: 12px 16px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #373737;
  margin-top: 16px;
}

.addButton::after {
  content: "";
  position: absolute;
  right: 16px;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../assets/images/SmallBlackCross.svg");
}

@media (min-width: 1280px) {
  .addSection {
    width: 295px;
  }
}
