.text {
  height: 48px;
  width: 100%;
  padding: 0 12px;
  line-height: 48px;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 48px;
  border: none;
  outline: none;
}

.text:focus {
  outline: #5c8cf0;
  border: 2px solid #5c8cf0;
  padding: 0 10px;
}

.text::placeholder {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #b5bfcc;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
