.bikePassEdit {
  position: relative;
  padding: 0 16px;
  max-width: 390px;
  margin: 0 auto;
}

.pageTitle {
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 56px;
  text-align: center;
  color: #2e3840;
  margin-bottom: 8px;
}

.bikePhoto {
  width: 100%;
  height: 342px;
  object-fit: contain;
  cursor: pointer;
}

.footerFiller {
  height: 80px;
}

.buttonsContainer {
  position: fixed;
  bottom: 8px;
  display: flex;
  gap: 16px;
  width: 358px;
}

.subHeader {
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #373737;
  padding: 18px 0 8px;
  border-top: 2px solid #d9d9d9;
  margin-top: 24px;
}

.subDescription {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #737373;
}

.mandatoryInputs {
  padding-top: 8px;
}

.inputLabel {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #373737;
  padding: 16px 0 8px;
}

.addDocument {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 16px;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 48px;
  margin-top: 16px;
  cursor: pointer;
}

.addDocumentText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3b75ed;
}

.addDocumentIcon {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background: url("../../assets/images/AddBlueDocument.svg");
}

.optionalSection {
  background-color: transparent;
  padding: 16px 0 0;
  margin-top: 24px;
  box-shadow: none;
  border-top: 2px solid #d9d9d9;
  border-radius: unset;
}

.descriptionInput {
  margin-top: 8px;
  min-height: 180px;
  margin-bottom: 8px;
  overflow-y: hidden;
}

.addDocumentInput {
  display: none;
}

.invoice {
  padding: 12px 16px;
  margin-top: 16px;
  background: #ffffff;
  box-shadow: 0px 12px 24px -4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  cursor: pointer;
}

.invoiceBody {
  display: flex;
}

.invoiceIcon {
  width: 48px;
  height: 48px;
  background: url("../../assets/images/Invoice.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.invoiceInfo {
  padding-left: 8px;
}

.invoiceView {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3b75ed;
  padding-bottom: 4px;
}

.invoiceUploadTime {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #737373;
}

.invoiceFooter {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  margin-top: 12px;
  border-top: 2px solid #f3f3f3;
  cursor: pointer;
}

.invoiceFooterText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ef4444;
}

.invoiceFooterIcon {
  width: 24px;
  height: 24px;
  background: url("../../assets/images/RedTrash.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.section {
  position: relative;
}

.addNewImage {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.addNewImageIcon {
  width: 48px;
  height: 48px;
  background-color: #02f596;
  border-radius: 50%;
  background-image: url("../../assets/images/AddNewImage.svg");
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center center;
}

.addNewImageText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #373737;
  padding-top: 8px;
}

.replaceImageContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
}

.replaceImage {
  display: flex;
  width: fit-content;
  cursor: pointer;
}

.replaceImageText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3b75ed;
  padding-right: 8px;
}

.replaceImageIcon {
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/ReplacePhoto.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.username {
  display: flex;
  gap: 16px;
  width: 100%;
}

.inputPicker {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #2e3840;
  margin-top: 0.5rem;
  padding: 12px;
  height: 3rem;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0.38rem 0.75rem -0.13rem rgba(0, 0, 0, 0.1);
  border-radius: 3rem;
  border: none;
  caret-color: transparent;
}

@media (min-width: 1280px) {
  .bikePassEdit {
    max-width: none;
    padding-bottom: 72px;
  }

  .footerFiller {
    display: none;
  }

  .buttonsContainer {
    position: static;
    width: 100%;
  }
}
