.sectionParameterTitle {
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #5e7387;
  padding-bottom: 8px;
}

.sectionParameterValue {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  min-height: 20px;
  line-height: 20px;
  color: #2e3840;
  width: 100%;
}
