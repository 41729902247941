.parameter {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 16px;
}

.parameterTitle {
  padding-bottom: 8px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2e3840;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
