.bikeOptions {
  padding: 8px 16px 16px;
  max-width: 390px;
  margin: 0 auto;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
}

.backButton {
  position: absolute;
  left: -8px;
  top: 8px;
  width: 40px;
  height: 40px;
  background: #fff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../assets/images/ChevronLeft.svg");
  cursor: pointer;
}

.pageTitle {
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #272727;
}

.searchBar {
  margin-top: 8px;
  position: relative;
  flex-grow: 1;
}

.searchBar::after {
  content: "";
  position: absolute;
  top: 12px;
  right: 16px;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../assets/images/Search.svg");
}

.popular {
  padding-top: 16px;
}

.sectionTitle {
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #737373;
}

.popularList {
  padding-top: 8px;
  width: calc(100% + 16px);
  overflow-x: auto;
}

.popularListContainer {
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
  width: fit-content;
  padding-right: 16px;
  padding-bottom: 16px;
}

.popularElem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  min-width: 64px;
  height: 64px;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
}

.brandIcon {
  width: 56px;
  height: 56px;
  background-image: url("../../../assets/images/BikeBrandsSprite.png");
  background-position-y: 0;
}

.selectedPopularElem {
  position: relative;
  border: 2px solid #3b75ed;
  background-position-y: -6px;
}

.options {
  padding-top: 16px;
}

.optionsHeader {
  display: flex;
  justify-content: center;
}

.optionsBody {
  margin-top: 8px;
}

.optionsHeaderElem {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  color: #3b75ed;
  cursor: pointer;
}

.optionsChunkHeader {
  background: #f3f3f3;
  border-radius: 8px;
  padding: 6px 8px;
  margin-top: 20px;
  font-family: "Inter";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #272727;
}

.option {
  display: flex;
  align-items: center;
  color: #272727;
  padding: 2px 0;
  min-height: 48px;
  border-bottom: 2px solid #f3f3f3;
  cursor: pointer;
}

.optionText {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 32px;
  margin-right: 8px;
}

.selectedOptionText {
  position: relative;
  color: #3b75ed;
  overflow: unset;
  white-space: unset;
  text-overflow: unset;
}

.selectedOptionText::after {
  content: "";
  position: absolute;
  top: 0px;
  right: 0;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../assets/images/BlueCheck.svg");
}

.optionImage {
  width: 80px;
  min-width: 80px;
  height: 80px;
  background-size: cover;
  background-position: center;
}

.footerFiller {
  height: 64px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  padding: 8px 16px;
  z-index: 1;
}

.submitButton {
  display: flex;
  justify-content: space-between;
  background: #02f596;
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.2);
  border-radius: 40px;
  padding: 12px 16px;
  cursor: pointer;
}

.submitButtonText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #272727;
}

.submitButtonIcon {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../assets/images/Check.svg");
}

.loader {
  margin: 24px auto 12px;
}

@media (min-width: 1280px) {
  .footer {
    padding-left: 10%;
    padding-right: 60%;
  }

  .popularListContainer {
    flex-wrap: wrap;
  }
}
