.card {
  background: #ffffff;
  box-shadow: 0 12px 24px -4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 16px;
  margin: 16px auto;
}

.bikeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconBike {
  height: 60px;
  object-fit: cover;
}
.wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-right: 16px;
}
.text {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.08px;
  text-transform: uppercase;
  color: #5e7387;
}

.modelText {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: #2e3840;
}
