.stub {
}

.pageWrapper {
  padding: 0 16px 16px;
}

.label,
.dateLabel {
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  color: #2e3840;
  margin-top: 1rem;
}

.dateLabel::after {
  content: url(../../../assets/images/Calendar.svg);
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 2.7rem;
  left: 1rem;
}

.errorMessage {
  color: red;
  text-align: center;
  margin-top: 0.625rem;
  font-size: 0.75rem;
}

.input,
.inputPicker {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #2e3840;
  margin-top: 0.5rem;
  padding: 12px;
  height: 3rem;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0.38rem 0.75rem -0.13rem rgba(0, 0, 0, 0.1);
  border-radius: 3rem;
  border: none;
  caret-color: transparent;
}

.input:focus {
  outline: #5c8cf0;
  border: 2px solid #5c8cf0;
  padding: 10px;
}

.inputPicker {
  padding-left: 44px;
}

.labelTitle {
  display: flex;
}

.tooltip {
  margin-left: 8px;
}
