.actionsPanel {
  display: flex;
  padding-top: 16px;
  justify-content: center;
  gap: 24px;
}

.action {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70px;
}

.actionIcon {
  width: 48px;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.actionIcon:hover {
  background-color: #f1f1f1;
}

.disabledAction {
  background-color: #e4e4e4;
  cursor: not-allowed;
  box-shadow: unset;
}

.disabledAction:hover {
  background-color: #e4e4e4;
}

.actionText {
  padding-top: 6px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #2e3840;
}

.actionDetails {
  background-image: url("../../../assets/images/ViewList.svg");
}
.actionShare {
  background-image: url("../../../assets/images/Share.svg");
  background-position: center 10px;
}
.actionPhoto {
  background-image: url("../../../assets/images/Photo.svg");
}
.actionDocument {
  background-image: url("../../../assets/images/AddDocument.svg");
}
.actionDamage {
  background-image: url("../../../assets/images/Damage.svg");
}
.actionTheft {
  background-image: url("../../../assets/images/Theft.svg");
}

@media (min-width: 1280px) {
  .action {
    flex-grow: 1;
  }

  .actionContainer {
    display: flex;
    flex-grow: 1;
  }

  .actionIcon {
    width: 100%;
  }

  .actionsPanel {
    padding-top: 32px;
  }
}
