@-webkit-keyframes spinner-animation {
  to {
    -webkit-transform:rotate(-180deg);
    -ms-transform:rotate(-180deg);
    transform:rotate(-180deg);
  }
}

@keyframes spinner-animation {
  to {
    transform:rotate(-180deg);
    -ms-transform:rotate(-180deg);
    -webkit-transform:rotate(-180deg);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.6;
  background: #2e3840;
  z-index: 100;
}
.spinnerWrapper {
  position: fixed;
  top: 40vh;
  left: 38vw;
  z-index: 101;
}

.spinner {
  position: relative;
  font-size: 0px;
  width: 100px;
  height: 100px;
  -webkit-animation: spinner-animation 0.75s steps(12,end) infinite;
  animation: spinner-animation 0.75s steps(12,end) infinite;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}

.spinnerLine {
  stroke-width: 3px;
  stroke-linecap: round;
}

.spinnerLine:nth-child(12n + 1) {
  stroke-opacity: 0.17;
}

.spinnerLine:nth-child(12n + 2) {
  stroke-opacity: 0.25;
}

.spinnerLine:nth-child(12n + 3) {
  stroke-opacity: 0.33;
}

.spinnerLine:nth-child(12n + 4) {
  stroke-opacity: 0.42;
}

.spinnerLine:nth-child(12n + 5) {
  stroke-opacity: 0.5;
}

.spinnerLine:nth-child(12n + 6) {
  stroke-opacity: 0.58;
}

.spinnerLine:nth-child(12n + 7) {
  stroke-opacity: 0.66;
}

.spinnerLine:nth-child(12n + 8) {
  stroke-opacity: 0.75;
}

.spinnerLine:nth-child(12n + 9) {
  stroke-opacity: 0.83;
}

.spinnerLine:nth-child(12n + 11) {
  stroke-opacity: 0.92;
}

.spinnerLine:nth-child(12n + 0) {
  stroke-opacity: 0.08;
}
