.dealer {
  position: relative;
  padding: 8px 16px;
}

.header {
  display: flex;
  justify-content: center;
}

.backButton {
  position: absolute;
  left: 16px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-image: url("../../assets/images/ChevronLeft.svg");
}

.pageTitle {
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  color: #2e3840;
}

.section {
  margin-top: 16px;
}

.section:last-child {
  padding-bottom: 22px;
}

.bikeSection {
  display: flex;
  flex-direction: row;
}

.bikeImage {
  width: 79px;
  height: 48px;
  object-fit: cover;
}

.bikeData {
  padding-left: 16px;
}

.brandName {
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #737373;
}

.modelName {
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #373737;
}

.dealerName {
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #373737;
}

.address {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #737373;
}

.zip {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #737373;
  padding-top: 4px;
}

.map {
  height: 160px;
  background: #d9d9d9;
  border-radius: 8px;
  margin-top: 8px;
}

.link {
  position: relative;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #3b75ed;
  padding-left: 32px;
  cursor: pointer;
}

.link::before {
  content: "";
  position: absolute;
  left: 0;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.openMaps {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 2px solid #f3f3f3;
}

.openMaps::before {
  background-image: url("../../assets/images/Map.svg");
}

.subTitle {
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #737373;
  padding: 16px 0;
}

.phoneNumber::before {
  background-image: url("../../assets/images/Phone.svg");
}

.phoneNumberSubtitle {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #737373;
  padding-top: 4px;
  padding-bottom: 8px;
  padding-left: 36px;
}

.email::before {
  background-image: url("../../assets/images/GreyMail.svg");
}

.website::before {
  background-image: url("../../assets/images/GreyWebsite.svg");
}

.instagram,
.facebook {
  padding-top: 8px;
}

.instagram::before {
  background-image: url("../../assets/images/GreyInstagram.svg");
}

.facebook::before {
  background-image: url("../../assets/images/GreyFacebook.svg");
}

.openHoursBlock {
  position: relative;
  display: flex;
  padding-bottom: 16px;
}

.openHoursBlock:last-child {
  padding-bottom: 0;
}

.dayName {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #373737;
  min-width: 40%;
}

.openHoursData {
  min-width: 40%;
}

.openHoursDataPiece {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #373737;
}

.loading {
  font-size: 3rem;
  text-align: center;
  padding-top: 40vh;
}
