.switcher {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  align-items: center;
  width: 100%;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
}

.option {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(50% - 10px);
  height: 40px;
  border-radius: 24px;
}

.chosen {
  background: #3b75ed;
  color: #fff;
}

.subtitle {
  width: 100%;
  padding: 16px 0 8px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2e3840;
}
