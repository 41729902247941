.sharingContainer {
  position: relative;
}

.popup {
  display: none;
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 0 16px;
  width: 240px;
  opacity: 0;
  transition: opacity 0.1s ease-in;
  z-index: 50;
}

.displayed {
  display: block;
}

.open {
  opacity: 1;
}

.popupItemContainer {
  border-top: 2px solid #f3f3f3;
}

.popupItemContainer:first-child {
  border-top: 0;
}

.popupItem {
  position: relative;
  display: block;
  padding: 12px 0 12px 40px !important;
  width: 100%;
  text-align: left;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #373737;
  cursor: pointer;
}

.popupItem::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.twitter::before {
  background-image: url("../../assets/images/Twitter.svg");
}

.facebook::before {
  background-image: url("../../assets/images/Facebook.svg");
}

.mail::before {
  background-image: url("../../assets/images/DarkMail.svg");
}

.whatsapp::before {
  background-image: url("../../assets/images/WhatsApp.svg");
}

.copyLink::before {
  background-image: url("../../assets/images/CopyLink.svg");
}
