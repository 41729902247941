.bikeParameter {
  width: calc(50% - 8px);
  padding-bottom: 23px;
}

.bikeParameterHeader {
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #737373;
  padding-bottom: 7px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bikeParameterValue {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #373737;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
