.label {
  display: flex;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0d0d0d;
  padding-bottom: 8px;
}
