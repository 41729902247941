.myAccount {
  max-width: 390px;
  margin: 0 auto;
}

.myAccountEdit {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.header {
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #2e3840;
  padding-bottom: 24px;
}

.label {
  font-family: "Inter";
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2e3840;
}

.inputContainer {
  padding-bottom: 16px;
}

.doubleInputs {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.halfSizeInput {
  width: calc(50% - 8px);
}
