.contentWrapper {
  padding: 8px 16px 96px;
  max-width: 390px;
  margin: 0 auto;
}
/* header */
.header {
  display: flex;
  justify-content: space-between;
}

.backButton {
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.backButton {
  background-image: url("../../assets/images/ChevronLeft.svg");
}

.pageTitle {
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  color: #2e3840;
}

/* Subject bike */
.card {
  background: #ffffff;
  box-shadow: 0 12px 24px -4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 16px;
  margin: 16px auto;
}

.bikeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconBike {
  display: block;
  background-image: url("../../assets/images/Bike.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 80px;
  height: 48px;
}
.wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  margin-right: 16px;
}
.text {
  font-weight: 600;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.08px;
  text-transform: uppercase;
  color: #5e7387;
}

.modelText {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: #2e3840;
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.08px;
  color: #2e3840;
}

.repairDescription {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.08px;
  color: #5e7387;
}

.formLabel {
  font-weight: 500;
  font-size: 13.5px;
  line-height: 1.5;
  letter-spacing: 0.08px;
  color: #2e3840;
}

.hr {
  background-color: #d1d9e0;
  margin-top: 16px;
  margin-bottom: 16px;
  opacity: 0.8;
  height: 2px;
  border: none;
}

/* bottom transparent action panel */

.actionWrapper {
  background-color: transparent;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 8px 16px 16px 16px;
  z-index: 99;
}

.btn {
  display: flex;
  align-items: center;
  background-color: #02f596;
  border: none;
  padding: 12px 16px 12px 16px;
  box-shadow: 0px 0px 2px rgba(77, 64, 0, 0.1),
    0px 8px 16px -2px rgba(77, 64, 0, 0.2);
  border-radius: 24px;
  justify-content: space-between;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  color: #2e3840;
  width: 100%;
  cursor: pointer;
}

/* Event */
.cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.completedLabel {
  background-color: #02f596;
  border-radius: 20px;
  padding: 4px 8px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #2e3840;
}

.plannedLabel {
  background-color: #ffde1a;
  border-radius: 20px;
  padding: 4px 8px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #2e3840;
}

.cardHeaderText {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #2e3840;
}

.dateEntry {
  font-size: 14px;
  line-height: 20px;
  color: #5e7387;
}

.smallText {
  font-size: 12px;
}

.primaryButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #3b75ed;
  border: none;
  padding: 12px 16px;
  border-radius: 8px;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  width: 100%;
  cursor: pointer;
}

/* Date picker */
.labelDatePicker {
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: #2e3840;
  margin-top: 16px;
}
.labelDatePicker::after {
  content: url("../../assets//images/Calendar.svg");
  width: 24px;
  height: 24px;
  position: absolute;
  top: 43.2px;
  left: 16px;
}

.inputPicker {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: #2e3840;
  margin-top: 8px;
  margin-bottom: 16px;
  padding: 12px;
  height: 48px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 48px;
  border: none;
  padding-left: 48px;
}

.inputPicker::placeholder {
  color: #2e3840;
}

.spacer {
  margin: 8px auto 16px;
}

.cursor {
  cursor: pointer;
}

.evidence {
  margin: 16px auto 8px;
}

/* textarea */
.textarea {
  width: 100%;
  border: none !important;
  outline: none !important;
  resize: none;
}

.textarea::placeholder {
  color: #b5bfcc;
}

.documentIcon {
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/Document.svg");
}

.replaceIcon {
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/Refresh.svg");
}

.pencilIcon {
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/Pencil.svg");
}

.bigFileIcon {
  width: 48px;
  height: 48px;
  background-image: url("../../assets/images/BigdocIcon.svg");
  margin-right: 8px;
}

.CancelIcon {
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/Cancel.svg");
}

.SaveIcon {
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/Save.svg");
}

.deleteIcon {
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/Trash.svg");
}

.document {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.previewImg {
  width: 48px;
  height: 48px;
}

.previewText {
  color: #5e7387;
  font-size: 12px;
}

.documentText {
  color: #3b75ed;
  font-weight: 600;
}

.viewDocument {
  font-size: 16px;
  line-height: 1.5;
}

.documentSubtitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5e7387;
}

.round {
  border-radius: 48px;
  padding: 12px 16px;
}

.actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
}

.white {
  background-color: #ffffff;
  color: #3b75ed;
}

.viewHeader {
  color: #5e7387;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.25;
  text-transform: uppercase;
  margin: 16px 0 8px;
}

.viewValue {
  color: #2e3840;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
}

.viewDocument {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.deleteText {
  color: #ef4444;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}

.marginTop {
  margin-top: 16px;
}

.LoadingText {
  font-size: 24px;
  color: #5e7387;
}

.LoadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 16px;
}

.errorMessage {
  color: red;
  text-align: center;
  margin-bottom: 10px;
  font-size: 12px;
}
