.stub {
}

.toastContainer {
  position: fixed;
  top: -20px;
  transform: translateY(-100%);
  right: 0;
  width: 100vw;
  max-width: 390px;
  padding: 0 16px;
  transition: transform 0.4s;
  z-index: 100;
}

.activeToastContainer {
  transform: translateY(36px);
}

.toast {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 22px 24px 16px;
}

.header {
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #272727;
}

.body {
  padding-top: 6px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #272727;
}

.closure {
  position: absolute;
  top: 16px;
  right: 14px;
  width: 24px;
  height: 24px;
  margin-left: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assets/images/GreyClosure.svg");
  z-index: 1;
  cursor: pointer;
}

@media (min-width: 1280px) {
  .toastContainer {
    max-width: 50vw;
  }

  .activeToastContainer {
    transform: translateY(100px);
  }
}
