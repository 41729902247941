.buttonWrapper {
  background-color: #f0f2f5;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0.5rem 1rem 1rem 1rem;
  box-shadow: 0px -4px 6px -1px rgba(0, 0, 0, 0.1),
    0px -2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 100;
}

.btn {
  display: flex;
  transition-duration: 0.4s;
  cursor: pointer;
  align-items: center;
  background-color: #02f596;
  border: none;
  padding: 0.75rem 1rem 0.75rem 1rem;
  box-shadow: 0px 0px 2px rgba(77, 64, 0, 0.1),
    0px 8px 16px -2px rgba(77, 64, 0, 0.2);
  border-radius: 2.5rem;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #2e3840;
  width: 100%;
}

.btn:hover {
  background-color: #4dfca6;
  color: black;
}

.btn:active {
  background-color: #45e38a;
  color: black;
}

.actionSection {
  width: 100%;
}

.stub {
  display: none;
}

@media (min-width: 1280px) {
  .buttonWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0.5rem 0 1rem 0;
  }

  .centeredButtonWrapper {
    display: block;
  }

  .stub {
    display: block;
  }

  .actionSection {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
  }

  .centeredButtonWrapper .actionSection {
    grid-template-columns: 1fr 2fr 1fr;
  }
} ;
