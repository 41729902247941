.socialPanel {
  padding: 16px 16px 25px;
}

.social {
  display: flex;
  gap: 16px;
}

.contact {
  height: 48px;
  line-height: 46px;
  border: 2px solid #d1d9e0;
  border-radius: 8px;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  background-color: #f3f3f3;
}

.invite {
  height: 48px;
  line-height: 46px;
  border: 2px solid #d1d9e0;
  border-radius: 8px;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  background-color: #f3f3f3;
}

.instagram {
  width: 46px;
  height: 48px;
  line-height: 46px;
  border: 2px solid #d1d9e0;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  background-image: url("../../../assets/images/Instagram.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f3f3f3;
}

.subscription {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  height: 48px;
  border: 2px solid #d1d9e0;
  box-sizing: border-box;
  border-radius: 8px;
}

.subscriptionLeftGroup {
  display: flex;
}

.emailIcon {
  width: 24px;
  height: 18px;
  background-image: url("../../../assets/images/Mail.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.subscriptionText {
  padding-left: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #2e3840;
}

.toggleContainer {
  padding-top: 2px;
}

@media (min-width: 1280px) {
  .socialPanel {
    padding: 16px 0 0;
  }

  .social {
    gap: 2px;
  }

  .contact,
  .invite,
  .instagram {
    position: relative;
    border: none;
    background-color: transparent;
    flex-grow: 0;
    padding: 16px 30px;
    line-height: normal;
  }

  .instagram {
    width: 64px;
    height: 48px;
  }

  .contact::after,
  .invite::after {
    content: "";
    position: absolute;
    top: 14px;
    right: 0;
    width: 2px;
    height: 24px;
    background: #d9d9d9;
  }
}
