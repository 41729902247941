.mainPhoto {
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  object-fit: cover;
}

.secondaryPhotos {
  display: flex;
  flex-wrap: nowrap;
  gap: 4px;
  padding: 8px 0 0px;
}

.secondaryPhoto {
  width: 48px;
  height: 48px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background: #f3f3f3;
  object-fit: cover;
}

.chosen {
  border: 2px solid #3b75ed;
}

.photosPadding {
  padding-bottom: 16px;
}

@media (min-width: 1280px) {
  .mainPhoto {
    width: 326px;
    margin: auto;
  }
}
