.section {
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.expandableContent {
  transition: height 0.2s ease-in-out;
  overflow: hidden;
}
