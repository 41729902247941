.welcome {
}
.welcome {
  max-width: 390px;
  margin: 0 auto;
}

.headerSection {
  position: relative;
  margin-left: 16px;
  height: 317px;
  background-repeat: no-repeat;
  background-position-y: 70%;
  background-image: url("../../../assets/images/WelcomeBG.png");
}

.header {
  display: flex;
  padding-top: 20px;
  align-items: center;
}

.logo {
  width: 108px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../../../assets/images/Logo.svg");
}

.closure {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../assets/images/Closure.svg");
  cursor: pointer;
}

.dealer {
  margin-left: 8px;
  padding: 0 16px;
  border: 2px solid #c1c1c1;
  border-radius: 16px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #272727;
}

.backgroundText {
  position: absolute;
  top: 220px;
  left: 236px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  color: #ffffff;
  transform: rotate(-35deg);
}

.descriptionSection {
  padding: 16px 16px 24px;
}

.pageTitle {
  font-family: "Inter";
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  color: #272727;
}

.bold {
  font-weight: 700;
}

.descriptionFeatures {
  padding-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.footerFiller {
  height: 128px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  padding: 8px 16px 12px;
  z-index: 1;
}

.footerTitle {
  font-family: "Inter";
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #272727;
}

.footerButtons {
  display: flex;
  gap: 16px;
  padding-top: 8px;
}

.footerButton {
  display: flex;
  justify-content: center;
  padding: 12px 0;
  border-radius: 24px;
  width: calc(50% - 8px);
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.2);
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;
}

.footerButtonBlue {
  background: #3b75ed;
  color: #ffffff;
}

.footerButtonGreen {
  background: #02f596;
  color: #272727;
}

.loginHint {
  padding-top: 12px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #272727;
}

.loginLink {
  color: #3b75ed;
  cursor: pointer;
}

.newBikeHeader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
}

.backButton {
  position: absolute;
  left: -8px;
  top: 8px;
  width: 40px;
  height: 40px;
  background: #fff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../assets/images/ChevronLeft.svg");
  cursor: pointer;
}

.pageTitle {
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #272727;
}

@media (min-width: 1280px) {
  .footer {
    padding-left: 10%;
    padding-right: 60%;
  }

  .headerSection {
    height: auto;
    background-image: none;
  }

  .descriptionSection {
    padding-top: 40px;
  }

  .desktopAsideImg {
    justify-content: unset;
  }
}
