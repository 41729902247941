.label {
  display: flex;
  flex-direction: column;
  position: relative;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  color: #2e3840;
  margin-top: 1rem;
}

.input {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #b5bfcc;
  padding: 0.75rem;
  height: 3rem;
  background: #ffffff;
  box-shadow: 0 0.375rem 0.75rem -0.125rem rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;
  border: none;
  margin-top: 0.5rem;
}

.input:focus {
  outline: #5c8cf0;
  border: 2px solid #5c8cf0;
}

.input:not(placeholder-shown) {
  color: #2e3840;
}

.errorMessage {
  color: red;
  text-align: center;
  margin-top: 0.625rem;
  font-size: 0.75rem;
}

.contentWrapper {
  padding-top: 27px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 136px;
}

.title {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1;
  color: #2e3840;
}

.forgotPassword {
  display: block;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  color:#3B75ED;
  margin-top: 1.5rem;
}

.register {
  font-weight: 500;
  font-size: 0.92rem;
  line-height: 1.5;
  color:#2e3840;
  margin-top: 1rem;
}

.highlight {
  color:#3B75ED;
}

@media (min-width: 1280px) {
  .contentWrapper {
    padding-left: 0;
    padding-right: 0;
  }
};