.carouselWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 200;
  background-color: black;
}

.itemWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 72px);
}

.carouseImage {
  max-width: 100% !important;
  max-height: 100% !important;
  width: auto !important;
  height: auto;
}

.close {
  background-image: url("../../assets/images/CrossGrey.svg") !important;
  position: absolute;
  top: 16px;
  right: 44px;
  height: 30px;
  width: 30px;
  z-index: 10;
  cursor: pointer;
  background-size: contain;
}
