.descriptionFeature {
  position: relative;
  width: calc(50% - 8px);
  padding-left: 24px;
}

.descriptionFeature::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../../assets/images/CheckedCircleBlue.svg");
  z-index: 1;
}

.descriptionFeatureTitle {
  font-family: "Inter";
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #272727;
}

.descriptionFeatureText {
  padding-top: 8px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #272727;
}
