/* Desktop View */

@media (min-width: 1280px) {
  .desktop {
    display: flex;
    flex-direction: row;
  }

  .aside {
    flex: 1;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .page {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    height: 100vh;
    overflow-y: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .page::-webkit-scrollbar {
    display: none;
  }

  .asideImg {
    max-width: 652px;
    object-fit: cover;
  }

  @media screen and (max-width: 700px) {
    .page {
      grid-template-columns: 0fr 5fr 0fr;
      height: auto;
    }
  }
}

@media screen and (max-width: 1280px) {
  .aside {
    display: none;
  }
}
