.wrapperCheckbox {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
.checkboxText {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  color: #6b7280;
  padding-top: 0.125rem;
  cursor:pointer;
}
.checkboxTextError {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  color: #6b7280;
  padding-top: 0.125rem;
}
.customCheckbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  cursor:pointer;
}

.customCheckbox + .checkboxText {
  display: inline-flex;
  align-items: flex-start;
}
.customCheckbox + .checkboxTextError {
  display: inline-flex;
  align-items: flex-start;
}
.customCheckbox + .checkboxText::before {
  content: "";
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  flex-grow: 0;
  border: 0.125rem solid #3b75ed;
  border-radius: 0.25rem;
  margin-right: 0.625rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  background-color: #ffffff;
}
.customCheckbox + .checkboxTextError::before {
  content: "";
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  flex-grow: 0;
  border: 0.125rem solid red;
  border-radius: 0.25rem;
  margin-right: 0.625rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  background-color: #ffffff;
}
.customCheckbox:checked + .checkboxText::before {
  border-color: #3b75ed;
  background-color: #3b75ed;
  background-image: url("../../assets/images/Checked.svg");
}
.customCheckbox:checked + .checkboxTextError::before {
  border-color: #3b75ed;
  background-color: #3b75ed;
  background-image: url("../../assets/images/Checked.svg");
}
