.addBike {
  display: flex;
  flex-direction: column;
  max-width: 390px;
}

.addSection {
  display: flex;
  align-items: center;
  height: 280px;
  width: 100%;
  align-self: flex-end;
  background: #ffffff;
  box-shadow: 0px 12px 24px -4px rgb(0 0 0 / 10%);
  border-radius: 8px;
  background-image: url("../../../assets/images/BikeCardAddOnEmpty.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.header {
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #373737;
  padding-top: 24px;
}

.description {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #737373;
  padding-top: 16px;
}

.addButton {
  position: relative;
  background: #02f596;
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  padding: 12px 16px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #373737;
  margin-top: 16px;
  cursor: pointer;
}

.addButton::after {
  content: "";
  position: absolute;
  right: 16px;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../assets/images/SmallBlackCross.svg");
}
