/* Desktop View */

@media (min-width: 1280px) {
  .desktop {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-image: url("../../assets/images/VelioDesktopBackground.svg");
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .page {
    flex: 1;
    display: flex;
    justify-content: center;
    overflow-y: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .page::-webkit-scrollbar {
    display: none;
  }

  .wrapper {
    margin-bottom: 16px;
    width: 50vw;
  }

  .footer {
    display: flex;
    justify-content: center;
    background-color: #f0f2f5;
    position: fixed;
    width: 100vw;
    bottom: 0;
    left: 0;
    padding: 0.5rem 0 1rem;
    box-shadow: 0px -4px 6px -1px rgba(0, 0, 0, 0.1),
      0px -2px 4px -1px rgba(0, 0, 0, 0.06);
    z-index: 99;
  }

  .footerContainer {
    width: 50vw;
  }
}
