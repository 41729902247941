.section {
  margin-top: 16px;
}

.sectionContent {
  display: flex;
  column-gap: 16px;
  flex-wrap: wrap;
  padding-top: 16px;
  padding-bottom: 24px;
}

.sectionContent:last-child {
  padding-bottom: 8px;
}

.sectionSubHeader {
  width: 100%;
  background: #f0f2f5;
  border-radius: 8px;
  height: 36px;
  line-height: 36px;
  padding-left: 8px;
  margin-bottom: 16px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  color: #2e3840;
}

.sectionParameter {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 2px solid #f0f2f5;
}

.sectionParameter:first-child {
  padding-top: 0;
  border-top: none;
}

.sectionParameter:last-child {
  padding-bottom: 0;
}

.subtitle {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #2e3840;
  padding: 8px;
  background: #f0f2f5;
  border-radius: 8px;
  width: 100%;
}

.subtitle + .sectionParameter {
  border-top: none;
}
