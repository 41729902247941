.photos {
  position: relative;
  padding: 8px 16px;
  max-width: 390px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: center;
}

.bottomButtons {
  display: flex;
  align-items: center;
  background-color: #02f596;
  border: none;
  padding: 12px 16px 12px 16px;
  box-shadow: 0px 0px 2px rgba(77, 64, 0, 0.1),
    0px 8px 16px -2px rgba(77, 64, 0, 0.2);
  border-radius: 24px;
  justify-content: space-between;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  color: #2e3840;
  width: 100%;
  cursor: pointer;
}

.backButton {
  position: absolute;
  left: 8px;
  top: 8px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-image: url("../../assets/images/ChevronLeft.svg");
}

.pageTitle {
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  color: #2e3840;
}

.section {
  margin-top: 16px;
  padding: 0px;
}

.sectionContent {
  overflow: visible;
}

.coverPhoto {
  border-radius: 16px;
  position: relative;
}

.photoInput {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-image: url("../../assets/images/ReplacePhotoIcon.svg");
}

.newPhotoInput {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  width: 40px;
  height: 40px;
  background: #02f596;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-image: url("../../assets/images/AddPhotoIconGreen.svg");
}

.subheader {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #737373;
  text-align: center;
  padding: 12px;
}

.previewSection {
  display: grid;
  gap: 16px;
  padding: 16px 0 72px;
  grid-template-columns: calc(50% - 8px) calc(50% - 8px);
}

.regularPhotoWrapper {
  position: relative;
}

.regularPhoto {
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.regularPhotoInput {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-image: url("../../assets/images/Trash.svg");
}

.tooltipStyle {
  background: #ffffff !important;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2),
    0px 4px 8px -2px rgba(0, 0, 0, 0.1);
  border-radius: 16px !important;
  padding: 0 !important;
  opacity: 1 !important;
}

.dropdown {
  padding: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  min-width: 100px;
  color: #ef4444;
  cursor: pointer;
}

.actionWrapper {
  background-color: transparent;
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 16px 0px 8px 0px;
  max-width: 358px;
  z-index: 99;
}

.buttonPhotoIcon {
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/AddPhotoIconSmall.svg");
}

@media (min-width: 1280px) {
  .header {
    padding-bottom: 10px;
  }

  .photos {
    max-width: unset;
    padding-bottom: 88px;
  }

  .section {
    margin: auto;
    max-width: 432px;
  }

  .previewSection {
    grid-template-columns: repeat(auto-fit, 204px);
    justify-content: center;
  }
}
