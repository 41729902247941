.myAccount {
  max-width: 390px;
  margin: 0 auto;
}

.myAccountContent {
  padding: 16px 16px 0;
}

.header {
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #2e3840;
  padding-bottom: 24px;
}

.section {
  margin-bottom: 16px;
}

.sectionHeader {
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #2e3840;
}

.birthday {
  padding-top: 4px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #2e3840;
}

.email {
  padding-top: 4px;
  padding-bottom: 16px;
  border-bottom: 2px solid #f0f2f5;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #2e3840;
}

.contact {
  padding-top: 16px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #5e7387;
}

.legalElem {
  display: block;
  position: relative;
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2e3840;
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 2px solid #f0f2f5;
  cursor: pointer;
}

.legalElem:last-child {
  padding-bottom: 0;
}

.legalElem::after {
  content: "";
  position: absolute;
  right: 0;
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/ExternalLink.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.marginTop {
  margin-top: 16px;
}

.langElem {
  position: relative;
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2e3840;
  padding-left: 32px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-top: 2px solid #f0f2f5;
  cursor: pointer;
}

.langElem:last-child {
  padding-bottom: 0;
}

.address,
.phone {
  width: 210px;
  position: relative;
  padding-top: 8px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #2e3840;
  padding-left: 32px;
}

.phone {
  padding-bottom: 16px;
}

.chosenLang::before,
.address::before,
.phone::before {
  content: "";
  position: absolute;
  left: 0;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
}

.chosenLang::before {
  background-image: url("../../assets/images/CheckBlue.svg");
}

.address::before {
  background-image: url("../../assets/images/LocationMarker.svg");
}

.phone::before {
  background-image: url("../../assets/images/Phone.svg");
}

.logOutButton {
  position: relative;
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #3b75ed;
  cursor:pointer;
}

.logOutButton::after {
  content: "";
  position: absolute;
  right: 0;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/images/Logout.svg");
}
