@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?1o7je9");
  src: url("fonts/icomoon.eot?1o7je9#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?1o7je9") format("truetype"),
    url("fonts/icomoon.woff?1o7je9") format("woff"),
    url("fonts/icomoon.svg?1o7je9#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Lock:before {
  content: "\e900";
  position: absolute;
  top: 50%;
  left: 1rem;
  color: #5e7387;
  font-size: 1.125rem;
}
.icon-Logo:before {
  content: "\e901";
  font-size: 1.5rem;
}
.icon-Logo {
  margin-bottom: 1.5rem;
}

.icon-Logo-white:before {
  content: "\e901";
  font-size: 1.5rem;
  color: #ffffff;
}
.icon-coin-euro:before {
  content: "\e902";
  position: absolute;
  top: 57%;
  left: 1rem;
  color: #5e7387;
  font-size: 1.125rem;
}
.icon-coin-euro-bank:before {
  content: "\e902";
  margin-top: 0.187rem;
  margin-right: 1.25rem;
  color: #3b75ed;
  font-size: 1.687rem;
}
.icon-Arrow:before {
  content: "\e903";
}
.icon-Arrow-blue:before {
  content: "\e903";
}
.icon-Arrow-blue {
  color: #3b75ed;
}
.icon-small-lock:before {
  content: "\e904";
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.625rem;
}
