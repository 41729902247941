.mapContainer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  border-radius: 8px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.map {
  position: relative;
  top: -103px;
  left: -83px;
  height: calc(100% + 103px);
  width: calc(100% + 166px);
  border: none;
}
