.languageWidget {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}

.languageIcon {
  width: 24px;
  height: 24px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
}

.deutschIcon {
  background-image: url("../../../assets/images/DeutschIcon.png");
}

.englishIcon {
  background-image: url("../../../assets/images/EnglishIcon.png");
}

.unsetIcon {
  display: none;
}

.languageName {
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #f3f3f3;
  padding-left: 8px;
}

.switcherIcon {
  width: 24px;
  height: 24px;
  background-image: url("../../../assets/images/LightChevron.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.dropdown {
  position: absolute;
  top: -10px;
  right: -140px;
  width: 125px;
}

.dropdownElement {
  text-align: center;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #f3f3f3;
  padding-left: 8px;
  border: 2px solid #3f3f3f;
  border-bottom: none;
  cursor: pointer;
}

.dropdownElement:last-child {
  border-bottom: 2px solid #3f3f3f;
}
