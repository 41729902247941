.bikeCard {
  position: relative;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 0.75rem 1.5rem -0.25rem rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 8px 50px 8px 8px;
  margin-bottom: 12px;
}

.iconBike {
  display: block;
  object-fit: cover;
  width: 64px;
  height: 64px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 0.75rem;
  flex-grow: 1;
  flex-basis: 0px;
  width: 0;
}

.priceWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.33;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #5e7387;
}

.price {
  position: absolute;
  top: 20px;
  right: 16px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #3b75ed;
}

.modelText {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #2e3840;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
