.button {
  background-color: #02f596;
}

.text {
  color: #2e3840;
}

.icon {
  background-image: url('../../../assets/images/Check.svg');
}
