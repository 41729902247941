.stubClass {
  display: block;
}
.container {
  background: #2e3840 url(../../assets/images/BikeType.svg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.5rem 1rem 2.75rem 1rem;
}
.logoWrapper {
  display: flex;
  align-items: center;
}
.logo::before {
  content: url(../../assets/images/LogoWhite.svg);
}
.iconCross {
  background-image: url(../../assets/images/IconCrossBikeTypePage.svg);
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.dealerName {
  display: block;
  border: 0.125rem solid #5e7387;
  color: #ffffff;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 1rem;
  padding: 0.25rem 1rem 0.25rem 1rem;
}
.subtitle {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.33;
  color: #d1d9e0;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  margin-top: 2.5rem;
}
.mainTitle {
  color: #ffffff;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 0.5rem;
}
.descr {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #b5bfcc;
}