.stubClass {
  display: block;
}
.insuranceInterface {
  position: relative;
}
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.75rem;
}
.line {
  display: block;
  width: 3.5rem;
  height: 0.125rem;
  background-color: #b5bfcc;
}
.circle {
  display: block;
  width: 0.857rem;
  height: 0.857rem;
  border-radius: 50%;
  background-color: #b5bfcc;
  margin-right: 0.125rem;
  margin-left: 0.125rem;
}
.text {
  font-family: "Inter";
  font-size: 0.75rem;
  color: #5e7387;
  line-height: 1.33;
  font-weight: 500;
}
.activeText {
  font-family: "Inter";
  font-size: 0.75rem;
  color: #2e3840;
  line-height: 1.33;
  font-weight: 500;
}
.activeLine {
  display: block;
  width: 3.5rem;
  height: 0.125rem;
  background-color: #02f596;
}
.firstLine {
  display: block;
  width: 1.75rem;
  height: 0.125rem;
  background-color: #02f596;
}
.lastLine {
  display: block;
  width: 1.75rem;
  height: 0.125rem;
  background-color: #b5bfcc;
}
.activeCircle {
  display: block;
  width: 0.857rem;
  height: 0.857rem;
  border-radius: 50%;
  background-color: #02f596;
  margin-right: 0.125rem;
  margin-left: 0.125rem;
}
.list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.firstItem {
  margin-left: 0.5rem;
  margin-right: 2.6em;
}
.secondItem {
  margin-right: 2.2rem;
}
.thirdItem {
  margin-right: 2rem;
}
.fourthItem {
  margin-right: 1.4rem;
}
.backButton {
  position: absolute;
  left: 0px;
  top: 8px;
  width: 40px;
  height: 40px;
  background: #fff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../../assets/images/ChevronLeft.svg");
  cursor: pointer;
}
.mainPageTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 54px;
  text-align: center;
  color: #0d0d0d;
  padding-bottom: 16px;
}
.offerWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.offerText {
  display: flex;
  text-align: center;
  font-family: "Inter";
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #0d0d0d;
  background: #ffde1a;
  border-radius: 20px;
  padding: 2px 8px;
}
.offerText:not(:last-child) {
  margin-right: 8px;
}
.tooltip {
  display: inline-block;
  background-image: url(../../../../assets/images/Tooltip.svg);
  margin-left: 3px;
  width: 14px;
  height: 14px;
  background-size: contain;
  background-repeat: no-repeat;
}

@media screen and (max-width: 380px) {
  .firstItem {
    margin-left: 1.4rem;
    margin-right: 1.9em;
  }
  .secondItem {
    margin-right: 1.8rem;
  }
  .thirdItem {
    margin-right: 1.9rem;
  }
  .fourthItem {
    margin-right: 1.2rem;
  }
}
