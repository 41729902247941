.title {
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 3rem;
  line-height: 1;
  color: #2e3840;
}
.descriptionTitle {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.2;
  color: #5e7387;
  margin-bottom: 0.5rem;
}
.form {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
}
.wrapper {
  display: flex;
}
.nameWrapper {
  margin-top: 1rem;
}
.label {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: Inter;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  color: #2e3840;
  flex: 1;
}
.labelDatePicker {
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  color: #2e3840;
}
.labelDatePicker::after {
  content: url(../../../assets/images/Calendar.svg);
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 2.7rem;
  left: 1rem;
}
.inputName {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #b5bfcc;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.75rem;
  height: 3rem;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0.38rem 0.75rem -0.13rem rgba(0, 0, 0, 0.1);
  border-radius: 3rem;
  border: none;
}
.inputName:focus {
  outline: #5c8cf0;
  border: 2px solid #5c8cf0;
}
.inputName:not(placeholder-shown) {
  color: #2e3840;
}
.labelLastName {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  color: #2e3840;
  margin-left: 1rem;
  flex: 1;
}
.input {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #b5bfcc;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.75rem;
  height: 3rem;
  background: #ffffff;
  box-shadow: 0 0.38rem 0.75rem -0.13rem rgba(0, 0, 0, 0.1);
  border-radius: 3rem;
  border: none;
}
.input:focus {
  outline: #5c8cf0;
  border: 2px solid #5c8cf0;
}
.input:not(placeholder-shown) {
  color: #2e3840;
}
.inputLarge {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #b5bfcc;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.75rem;
  height: 3rem;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0.38rem 0.75rem -0.13rem rgba(0, 0, 0, 0.1);
  border-radius: 3rem;
  border: none;
  padding-left: 3rem;
}
.inputLarge::placeholder {
  color: #b5bfcc;
}
.inputLarge:not(:placeholder-shown) {
  color: #2e3840;
}
.inputLarge:focus {
  outline: #5c8cf0;
  border: 2px solid #5c8cf0;
}
.email {
  position: absolute;
  top: 44%;
  left: 1rem;
  color: #5e7387;
  font-size: 1.13rem;
}
.inputCredential {
  font-family: "Inter";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #b5bfcc;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.75rem 0.75rem 0.75rem 2.81rem;
  height: 3rem;
  background: #ffffff;
  box-shadow: 0rem 0rem 0.13rem rgba(77, 64, 0, 0.1),
    0rem 0.38rem 0.75rem -0.13rem rgba(77, 64, 0, 0.16);
  border-radius: 2.5rem;
  border: none;
}
.inputCredential:focus {
  outline: #5c8cf0;
  border: 2px solid #5c8cf0;
}
.inputCredential:not(placeholder-shown) {
  color: #2e3840;
}
.errorMessage {
  color: red;
  text-align: center;
  margin-bottom: 0.625rem;
  font-size: 0.75rem;
}
.labelPhone {
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  color: #2e3840;
}
.labelPhone:after {
  content: url(../../../assets/images/Phone.svg);
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 2.7rem;
  left: 1rem;
}
.toast {
  border: 0.125rem solid red;
  color: #2e3840;
  font-size: 0.875rem;
}
.toastButton {
  width: 2rem;
  height: 2rem;
  background-image: url(../../../assets/images/CrossRed.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border: none;
  background-color: #ffffff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
