.container {
  position: relative;
}

.euroIcon {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 12px;
  top: 12px;
  background-image: url("../../../assets/images/Euro.svg");
  background-position: center;
  background-repeat: no-repeat;
}

.cash {
  height: 48px;
  width: 100%;
  padding-left: 44px;
  line-height: 48px;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 48px;
  border: none;
  outline: none;
}

.cash:focus {
  outline: #5c8cf0;
  border: 2px solid #5c8cf0;
}

.cash::placeholder {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #b5bfcc;
}
