.buttonsFiller {
  height: 88px;
  width: 100%;
}

.buttonsContainer {
  position: fixed;
  bottom: 16px;
  display: flex;
  gap: 16px;
  width: 100%;
}

.button {
  width: calc(50% - 24px);
}

@media (min-width: 1280px) {
  .buttonsContainer {
    position: static;
  }

  .buttonsFiller {
    display: none;
  }
}
