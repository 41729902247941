.stub {
}
.pageWrapper {
  padding: 0 16px;
}
.title {
  font-weight: 700;
  font-size: 3rem;
  line-height: 1;
  color: #2e3840;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
.descriptionTitle {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #5e7387;
  margin-bottom: 2.5rem;
}
.form {
  display: flex;
  flex-direction: column;
}
.label {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  color: #2e3840;
  margin-bottom: 0.5rem;
}
.bankName {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.43;
  color: #5e7387;
  margin-bottom: 1rem;
}
.labelDatePicker {
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: "Inter";
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  color: #2e3840;
}
.labelDatePicker::after {
  content: url(../../../assets/images/Calendar.svg);
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 2.7rem;
  left: 1rem;
}
.input {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #b5bfcc;
  margin-top: 0.5rem;
  padding: 0.75rem;
  height: 3rem;
  background: #ffffff;
  box-shadow: 0 0.38rem 0.75rem -0.13rem rgba(0, 0, 0, 0.1);
  border-radius: 3rem;
  border: none;
}
.input:not(:placeholder-shown) {
  color: #2e3840;
}

.input:focus {
  outline: 0.125rem solid #5c8cf0;
}
.inputPicker {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #b5bfcc;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding: 0.75rem;
  height: 3rem;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0.38rem 0.75rem -0.13rem rgba(0, 0, 0, 0.1);
  border-radius: 3rem;
  border: none;
  padding-left: 3rem;
}
.inputPicker::placeholder {
  color: #b5bfcc;
}
.inputPicker:not(:placeholder-shown) {
  color: #2e3840;
}
.inputPicker:focus {
  outline: 0.125rem solid #5c8cf0;
}
.inputIban {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: #b5bfcc;
  margin-top: 0.5rem;
  padding: 0.75rem;
  height: 3rem;
  background: #ffffff;
  box-shadow: 0 0.38rem 0.75rem -0.13rem rgba(0, 0, 0, 0.1);
  border-radius: 3rem;
  border: none;
}
.inputIban:focus {
  border-radius: 2.5rem;
  border: 0.06rem solid #3b75ed;
  outline: none;
}
.inputIban:not(:placeholder-shown) {
  color: #2e3840;
}
.btn {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: space-between;
  font-family: "Inter";
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  color: #2e3840;
  background-color: #02f596;
  border: none;
  padding: 0.5rem 1rem 0.5rem 1rem;
  box-shadow: 0 0.5rem 1rem -0.13rem rgba(0, 0, 0, 0.2);
  border-radius: 0.38rem;
  margin-top: 1.5rem;
  margin-bottom: 12.25rem;
}
.checkboxText {
  font-size: 0.875rem;
  font-weight: 600;
  padding-right: 0.7rem;
}

.firstText {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-left: 2.2rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  color: #6b7280;
  cursor: pointer;
}
.chevronDown::before {
  display: block;
  content: url("../../../assets/images/ChevronDown.svg");
  width: 1rem;
  padding-top: 0.35rem;
}
.chevronUp::before {
  display: block;
  content: url("../../../assets/images/ChevronUp.svg");
  width: 1rem;
  padding-top: 0.4rem;
}
.secondText {
  margin-left: 2.2rem;
  font-family: "Roboto";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  color: #6b7280;
}

.errorMessage {
  color: red;
  text-align: center;
  margin-bottom: 0.625rem;
  font-size: 0.75rem;
}
.toast {
  border: 0.125rem solid red;
  color: #2e3840;
  font-size: 0.875rem;
}
.toastButton {
  width: 2rem;
  height: 2rem;
  background-image: url(../../../assets/images/CrossRed.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border: none;
  background-color: #ffffff;
}
.note {
  font-family: "Roboto";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  color: #6b7280;
  margin-bottom: 1rem;
}
