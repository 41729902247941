.parameter {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 16px;
}

.parameterTitle {
  padding-bottom: 8px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2e3840;
}
