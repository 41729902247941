.container {
  position: relative;
}

.datePicker {
  position: relative;
  height: 48px;
  width: 100%;
  padding-left: 44px;
  line-height: 48px;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 48px;
  border: none;
  outline: none;
}

.datePicker::placeholder {
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #b5bfcc;
}

.calendarIcon {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 12px;
  top: 12px;
  background-image: url('../../../assets/images/Calendar.svg');
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}
