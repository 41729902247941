.pageWrapper {
  padding: 0 16px;
}

.title {
  font-weight: 700;
  font-size: 3rem;
  line-height: 1;
  color: #2e3840;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.wrapper {
  text-align: center;
}

.checkCircle {
  display: block;
  background-image: url("../../../assets/images/Check-circle.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 7.5rem;
  height: 7.5rem;
  margin: 0 auto;
}

.descriptionTitle {
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #0d0d0d;
  padding: 16px 0 24px;
}

.checkCircle:hover {
  transform: scale(1.1);
}

.wrapperText {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item {
  font-size: 1rem;
  line-height: 1.6;
  color: #2e3840;
  font-weight: 400;
}

.itemTitle {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1;
  color: #2e3840;
}

.listItem:not(:first-child) {
  padding-top: 1rem;
}

.listItem:not(:last-child) {
  border-bottom: 0.125rem solid #f0f2f5;
  padding-bottom: 1rem;
}

.list {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
}

.downloadLink {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 1.5rem;
  padding: 0.75rem 1rem;
  width: 100%;
  margin-top: 0.5rem;
  align-items: center;
  box-shadow: 0 0.5rem 1rem -0.125rem rgba(0, 0, 0, 0.1);
}

.downloadLink:hover {
  background-color: #e9f0fc;
}

.span {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.71;
  color: #3b75ed;
}

.iconDownload {
  display: block;
  background-image: url("../../../assets/images/DownloadIconBlue.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.5rem;
  height: 1.5rem;
}

.minorDescription {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #737373;
  padding-top: 21px;
}
