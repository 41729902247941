.statusesPanel {
  display: flex;
  padding-top: 16px;
  gap: 16px;
  flex-wrap: wrap;
}

.statusElement {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-width: calc(50% - 8px);
  height: 64px;
  padding: 12px 0 0 16px;
  border-radius: 8px !important;
  cursor: pointer;
}

.statusElement:hover {
  background-color: #f1f1f1;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #2e3840;
}

.highlightedTitle {
  color: #3b75ed;
}

.description {
  padding-top: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #5e7387;
}

.blueDescription {
  padding-top: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #3b75ed;
}

.blueText {
  color: #3b75ed;
}

.completionProgress {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.redCompletionProgress {
  background: #ff4747;
}

.orangeCompletionProgress {
  background: #f58502;
}

.greenCompletionProgress {
  background: #02f596;
}
