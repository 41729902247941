.linkWrapper {
  background-color: #f0f2f5;
  position: fixed;
  width: 100%;
  bottom:0;
  left: 0;
  padding: 0.5rem 1rem 1rem 1rem;
  box-shadow: 0px -4px 6px -1px rgba(0, 0, 0, 0.1),
    0px -2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 99;
}
.link {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.2;
  color: #2e3840;
  border: none;
  background: #02f596;
  box-shadow: 0px 0px 2px rgba(77, 64, 0, 0.1),
    0px 8px 16px -2px rgba(77, 64, 0, 0.2);
  border-radius: 3rem;
  width: 100%;
}
.link:hover{
  background-color: #4DFCA6; 
  color: black;
}

.link:active{
  background-color: #45E38A; 
  color: black;
}
.arrow {
  display: block;
  content: url("../../assets/images/BlackArrowIcon.svg");
}

.actionSection {
  width: 100%;
}

.stub {
  display: none;
}

@media (min-width: 1280px) {
  .linkWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0.5rem 0 1rem 0;
  }

  .stub {
    display: block
  }

  .actionSection {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
  }
};
