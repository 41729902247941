.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background: #0d0d0d;
  padding: 0 40px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 100;
}

.navigationTopRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
}

.navigationGroup {
  display: flex;
  align-items: center;
}

.absoluteNavigationGroup {
  position: absolute;
  display: flex;
  align-items: center;
  left: 224px;
}

.logo {
  width: 144px;
  height: 32px;
  font-size: 0;
  background-image: url("../../assets/images/VelioWhiteLogo.svg");
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 40px;
  cursor: pointer;
}

.link {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 3px 9px;
  color: #ffffff;
  border-radius: 16px;
  cursor: pointer;
}

.link:hover {
  background-color: #262626;
}

.active {
  color: #25cf7a;
}

.iconLink {
  font-size: 0px;
  margin-left: 18px;
  padding: 4px;
  border-radius: 50%;
  cursor: pointer;
}

.iconLink:hover {
  background-color: #262626;
}

.icon {
  width: 24px;
  height: 24px;
  background-size: cover;
  background-repeat: no-repeat;
}

.profileIcon {
  background-image: url("../../assets/images/WhiteProfile.svg");
}

.wishListIcon {
  background-image: url("../../assets/images/WhiteWishList.svg");
}

.cartIcon {
  background-image: url("../../assets/images/WhiteCart.svg");
}

.filler {
  height: 88px;
}

@media (max-width: 1280px) {
  .navigation {
    padding: 0 16px;
  }

  .navigationTopRow {
    height: auto;
    padding: 20px 0;
  }

  .logo {
    width: 108px;
    height: 24px;
  }

  .navigationBottomRow {
    display: flex;
    padding: 12px 0;
    border-top: 2px solid #272727;
    overflow-x: auto;
  }

  .link {
    flex-shrink: 0;
  }

  .link:first-child {
    margin-left: 0;
  }

  .filler {
    height: 130px;
  }
}
