.insurance {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  align-items: center;
  max-width: 390px;
  margin: 0 auto;
}

.insuranceContent {
  padding: 16px 16px 0;
}

.title {
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #2e3840;
  padding-bottom: 24px;
}

.section {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  padding: 16px 16px 0;
}

.sectionHeader {
  display: flex;
  border-bottom: 2px solid #f3f3f3;
  padding-bottom: 12px;
}

.bikeImage {
  width: 79px;
  height: 48px;
  object-fit: cover;
}

.bikeDescription {
  padding-top: 8px;
  padding-left: 16px;
}

.manufacturer {
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #737373;
}

.model {
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #373737;
}

.button {
  margin-top: 16px;
  margin-bottom: 24px;
  height: 44px;
  background: #02f596;
  box-shadow: 0px 0px 2px rgba(77, 64, 0, 0.1),
    0px 8px 16px -2px rgba(77, 64, 0, 0.2);
  border-radius: 40px;
}

.blueButton {
  background: #2e6bed;
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
}

.blueButton:hover {
  background: #4983ff;
}

.buttonText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #373737;
}

.blueButtonText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.buttonIcon {
  background-image: url("../../assets/images/ArrowRight.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.blueButtonIcon {
  background-image: url("../../assets/images/WhitePlus.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.package {
  padding: 12px 0;
  border-bottom: 2px solid #f3f3f3;
}

.packageTitle {
  padding-bottom: 8px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #737373;
}

.packageText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #373737;
}

.insuranceLink {
  position: relative;
  display: block;
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #373737;
  padding: 12px 0;
  border-bottom: 2px solid #f3f3f3;
  cursor: pointer;
}

.insuranceLink:last-child {
  border-bottom: none;
}

.insuranceLink::after {
  content: "";
  position: absolute;
  right: 0;
  top: 12px;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/images/ExternalLink.svg");
}

.insuranceMailLink::after {
  background-image: url("../../assets/images/Mail.svg");
}
