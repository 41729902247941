.routesWrapper {
  max-width: 390px;
  margin: 0 auto;
}

.main {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  padding: 16px;
}

.title {
  width: 100%;
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #2e3840;
  padding-bottom: 8px;
}

@media (min-width: 1280px) {
  .routesWrapper {
    max-width: unset;
  }

  .main {
    padding-bottom: 88px;
  }
}
