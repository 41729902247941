.insuranceStatus {
  position: absolute;
  right: 8px;
  top: 8px;
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
}

.insured {
  background: #02f596;
}

.notInsured {
  background: #373737;
  color: #f3f3f3;
}
