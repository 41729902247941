.textarea {
  min-height: 400px;
  width: 100%;
  padding: 16px;
  line-height: 24px;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  border: none;
  outline: none;
  resize: none;
}

.textarea:focus {
  outline: #5c8cf0;
  border: 2px solid #5c8cf0;
}

.text::placeholder {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #b5bfcc;
}
