.bikePass {
  position: relative;
  padding: 0 16px 16px;
  max-width: 390px;
  margin: 0 auto;
}

.header {
  height: 56px;
  margin-bottom: 8px;
}

.backButton {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-image: url("../../assets/images/ChevronLeft.svg");
}

.pageTitle {
  font-family: "Inter";
  font-weight: 700;
  font-size: 24px;
  line-height: 56px;
  text-align: center;
  color: #2e3840;
}

.section {
  position: relative;
  margin-bottom: 16px;
}

.bikePhoto {
  width: 100%;
  max-height: 326px;
  object-fit: cover;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  margin-bottom: 16px;
  cursor: pointer;
}

.subHeader {
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #373737;
  padding-bottom: 8px;
}

.paragraph {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #737373;
  padding-bottom: 16px;
}

.paragraph:last-child {
  padding-bottom: 0;
}

.completionProgress {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #f58502;
}

.bikeData {
  display: flex;
  flex-wrap: wrap;
  gap: 0 16px;
}

.sectionSmallerBottomPadding {
  padding-bottom: 12px;
}

.expandableContent {
  display: flex;
  flex-wrap: wrap;
  gap: 0 16px;
  padding-top: 16px;
  border-top: 2px solid #f3f3f3;
}

.bikeDescription {
  padding-top: 24px;
  padding-bottom: 16px;
  width: 100%;
  border-top: 2px solid #f3f3f3;
}

.link {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 2px solid #f0f2f5;
  padding: 12px 0;
  cursor: pointer;
}

.linkText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3b75ed;
}

.linkSymbol {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.linkSymbolNext {
  background-image: url("../../assets/images/ArrowRightBlue.svg");
}

.invoice {
  padding: 12px 16px;
  margin-top: 16px;
  background: #ffffff;
  box-shadow: 0px 12px 24px -4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  cursor: pointer;
}

.invoiceBody {
  display: flex;
}

.invoiceIcon {
  width: 48px;
  height: 48px;
  background: url("../../assets/images/Invoice.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.invoiceInfo {
  padding-left: 8px;
}

.invoiceView {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3b75ed;
  padding-bottom: 4px;
}

.invoiceUploadTime {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #737373;
}

.invoiceSectionTitle {
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #373737;
}

.invoiceSectionDescription {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #737373;
  padding-top: 16px;
}

.editCompleted {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 12px 24px -4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  height: 48px;
  padding: 0 16px;
  margin-top: 24px;
  cursor: pointer;
}

.editCompletedText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #3b75ed;
}

.editCompletedIcon {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/images/Pencil.svg");
}

@media (min-width: 1280px) {
  .bikePass {
    max-width: none;
    padding-bottom: 72px;
  }
}
