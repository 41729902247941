.routesWrapper {
  max-width: 390px;
  margin: 0 auto;
}

.garage {
  width: 100%;
  padding: 16px 16px 0;
}

.dotSlider {
  margin-bottom: 14px;
}

.dot {
  width: 48px !important;
  transition: max-width 0.1s linear;
}

.largeDot {
  max-width: 48px !important;
  height: 8px !important;
  background: #6b7280;
  border-radius: 8px;
  margin-left: 8px;
}

div:first-child > div > .largeDot:last-child {
  margin-left: 0px;
}

.mediumDot {
  max-width: 16px !important;
  height: 8px !important;
  background: #d1d9e0;
  border-radius: 8px;
  margin-left: 8px;
}

div:first-child > div > .mediumDot {
  margin-left: 0px;
}

.smallDot {
  max-width: 10px !important;
  height: 6px !important;
  background: #d1d9e0;
  border-radius: 8px;
  margin-left: 8px;
}

div:first-child > div > .smallDot {
  margin-left: 0px;
}

.tinyDot {
  max-width: 6px !important;
  height: 4px !important;
  background: #d1d9e0;
  border-radius: 8px;
  margin-left: 8px;
}

div:first-child > div > .tinyDot {
  margin-left: 0px;
}

.loading {
  font-size: 3rem;
  text-align: center;
  padding-top: 40vh;
}

.test1 {
  overflow-x: scroll;
  overflow-y: hidden;
}

.test2 {
  width: 5000px;
}

.bikeActionsSectionMobile {
  position: relative;
  top: -200px;
  height: 303px;
  transition: opacity 0.1s linear;
}

.opacity0 {
  opacity: 0;
}

.disabled {
  display: none;
}

@media (min-width: 1280px) {
  .routesWrapper {
    max-width: 100%;
  }

  .garage {
    padding-top: 0;
  }

  .desktopView {
    width: 50vw;
    margin-left: 25%;
  }

  .desktopSlider {
    overflow-x: auto;
    width: calc(75vw - 32px);
  }

  .sliderWrapper {
    display: flex;
    width: fit-content;
  }

  .desktopSlider::-webkit-scrollbar {
    width: 20px;
  }

  .desktopSlider::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .desktopSlider::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  .desktopSlider::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
}
