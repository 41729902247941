.routesWrapper {
  max-width: 390px;
  margin: 0 auto;
}

.inviteFriend {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  align-items: center;
}

.header {
  display: flex;
  justify-content: center;
  height: 56px;
  align-items: center;
}

.backButton {
  position: absolute;
  left: 16px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/images/ChevronLeft.svg");
  cursor: pointer;
}

.velioLogo {
  width: 108px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/images/VelioLogo.svg");
}

.title {
  padding-top: 8px;
  font-family: "Inter";
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  color: #2e3840;
  width: 300px;
}

.subTitle {
  padding-top: 16px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #2e3840;
}

.section {
  width: 100%;
  margin-top: 16px;
}

.sectionElem {
  position: relative;
  padding-left: 40px;
  padding-top: 16px;
}

.sectionElem:first-child {
  padding-top: 0;
}

.sectionElem::before {
  content: "";
  position: absolute;
  left: 0;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/images/CheckedCircle.svg");
  z-index: 1;
}

.sectionElemHeader {
  font-family: "Inter";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #2e3840;
}

.sectionElemText {
  padding-top: 4px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #5e7387;
}

.shareButtons {
  position: fixed;
  left: 16px;
  bottom: 8px;
  width: calc(100% - 32px);
}

.copyButton {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px 8px 44px;
  background: #ffffff;
  box-shadow: 0px 6px 12px -2px rgba(0, 0, 0, 0.1);
  border-radius: 48px;
  height: 48px;
  align-items: center;
  cursor: pointer;
}

.copyButton::before {
  content: "";
  position: absolute;
  left: 12px;
  top: 12px;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/images/Link.svg");
  z-index: 1;
}

.copyUrl {
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2e3840;
}

.copyText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3b75ed;
}

.copied {
  color: #272727;
}

.shareBikeButton {
  margin-top: 16px;
  height: 48px;
  line-height: 48px;
  background: #02f596;
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding: 0 6px 0 16px;
  text-align: center;
  cursor: pointer;
}

.shareBikeButtonText {
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  color: #2e3840;
  flex: none;
}

.shareButtonsFiller {
  height: 128px;
}

@media (min-width: 1280px) {
  .routesWrapper {
    max-width: unset;
  }

  .shareButtons {
    position: static;
    width: 100%;
  }
}
