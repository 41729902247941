html {
  box-sizing: border-box;
  width: 100vw;
  overflow-x: hidden;
}
*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  background-color: #f3f3f3;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3 {
  margin: 0;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
.react-hint > .react-hint__content {
  background-color: #1f262d;
  font-size: 0.75rem;
  width: 13.5rem;
}
div > .react-hint--top:after {
  border-top-color: #1f262d;
}
